<template>
    <div class="calculator-form">
        <form @submit.prevent="calculateMortgage">
            <div class="input-group">
                <div class="radio-options" @change="$event => resetLoan()">
                    <label>
                        <input type="radio" name="loanCategory" value="commercialLoan" v-model="loanCategory" />
                        商业贷款
                    </label>
                    <label>
                        <input type="radio" name="loanCategory" value="publicLoan" v-model="loanCategory" />
                        公积金贷款
                    </label>
                    <label>
                        <input type="radio" name="loanCategory" value="combinedLoan" v-model="loanCategory" />
                        组合贷款
                    </label>
                </div>
            </div>
            <div class="input-group">
                <label for="loanType">贷款方式:</label>
                <select id="loanType" v-model="loanType" required>
                    <option value="equalPrincipalAndInterest">等额本息</option>
                    <option value="equalPrincipal">等额本金</option>
                </select>
            </div>
            <div class="input-group" v-if="loanCategory === 'commercialLoan' || loanCategory === 'combinedLoan'">
                <label for="loanAmount">商业贷款金额（元）</label>
                <input type="number" id="loanAmount" v-model.number="loanAmount" required @change="$event => resetLoan()" />
            </div>
            <div class="input-group" v-if="loanCategory === 'publicLoan' || loanCategory === 'combinedLoan'">
                <label for="loanAmountPublic">公积金贷款金额（元）</label>
                <input type="number" id="loanAmountPublic" v-model.number="loanAmountPublic" required @change="$event => resetLoan()" />
            </div>
            <div class="input-group" v-if="loanCategory === 'commercialLoan' || loanCategory === 'combinedLoan'">
                <label for="interestRate">商业贷款年利率 (%):</label>
                <input type="number" id="interestRate" v-model.number="interestRate" step="0.01" required @change="$event => resetLoan()" />
            </div>
            <div class="input-group" v-if="loanCategory === 'publicLoan' || loanCategory === 'combinedLoan'">
                <label for="publicLoanInterestRate">公积金贷款年利率 (%):</label>
                <input type="number" id="publicLoanInterestRate" v-model.number="publicLoanInterestRate" step="0.01" required @change="$event => resetLoan()" />
            </div>
            <div class="input-group">
                <label for="loanTerm">贷款期限:</label>
                <select id="loanTerm" v-model.number="loanTerm" required @change="$event => resetLoan()">
                    <option v-for="option in loanTermOptions" :value="option.year" :key="option.year">
                        {{ option.note }}
                    </option>
                </select>
            </div>
            <div class="input-group">
                <label for="start-date">开始还款时间</label>
                <input type="date" id="start-date" v-model="startDate" required />
            </div>
            <div class="prepayment-options-group" v-for="(prepayment, index) in prepayments" :key="index">
                <button type="button" class="remove-prepayment-btn" @click="removePrepayment(index)">
                <span class="remove-icon">✕</span>
                </button>
                <div class="input-group">
                    <label>提前还款方式</label>
                    <select v-model="prepayment.method">
                        <option value="period">按还款期数输入</option>
                        <option value="time">按具体时间输入</option>
                    </select>
                </div>
                <div class="input-group" v-if="prepayment.method === 'period'">
                    <label for="prepayment-period" class="input-group-addon">提前还款期数</label>
                    <select v-model="prepayment.period" @change="$event => updateDateByPeriod(prepayment, index, $event.target.value)" class="form-control">
                        <option v-for="n in prepayment.periods" :value="n" :key="n">
                            第 {{ n }} 期
                            <template v-if="n % 12 === 1">{{ getPeriodYearMonth(n) }}</template>
                        </option>
                    </select>
                </div>
                <div class="input-group" v-if="prepayment.method === 'time'">
                    <label for="prepare-time">提前还款日期</label>
                    <input type="date" v-model="prepayment.prepareTime" @change="updatePeriodByDate(prepayment, index)">
                </div>
                <!-- 如果贷款方式不同，在需要选择提前还款类别 -->
                <div class="input-group" v-if="loanCategory === 'combinedLoan'">
                    <label for="prepayment-for" class="input-group-addon">提前还款类别</label>
                    <select id="prepayment-for" class="form-control" v-model="prepayment.for" @change="$event => updatePrepayment(prepayment, index, $event.target.value)">
                        <option value="publicLoan" selected>公积金贷款</option>
                        <option value="commercialLoan">商业贷款</option>
                    </select>
                </div>
                <div class="input-group">
                    <label for="prepayment-amount" class="input-group-addon">提前还款额</label>
                    <input type="number" id="prepayment-amount" v-model.number="prepayment.amount" class="form-control"
                        placeholder="请输入提前还款额" />
                </div>
                <div class="input-group">
                    <label for="repayment-method" class="input-group-addon">后续还款方式</label>
                    <select id="repayment-method" class="form-control" v-model="prepayment.type">
                        <option value="reduce-payment">减少每期还款金额</option>
                        <option value="reduce-terms">减少还款期限</option>
                    </select>
                </div>
            </div>

            <button type="button" class="add-prepayment-btn dashed-btn" @click="addPrepayment">添加提前还款</button>

            <button type="submit" class="calculate-btn">计算</button>
        </form>
    </div>
</template>
  
<script>
import { defineComponent, ref} from 'vue';
export default defineComponent({
    name: 'CalculatorForm',
    setup(_, { emit }) {

        const loanCategory = ref('commercialLoan');               // 贷款类别，公积金贷款、商业贷款或组合贷款
        const loanType = ref('equalPrincipalAndInterest');    // 贷款方式，等额本息或等额本金
        const loanAmount = ref(3000000);                          // 贷款金额
        const interestRate = ref(4.5);                        // 年利率
        const loanTerm = ref(20);                              // 贷款期限
        const loanAmountPublic = ref(500000);                    // 公积金贷款金额
        const publicLoanInterestRate = ref(3.25);             // 公积金贷款年利率
        const prepayments = ref([]); // 用于存储提前还款额的数组，示例：[{ period: 10, amount: 1000, type: reduce-terms }, { period: 提前还款的期数, amount: 提前还款数量, type: 提前还款后，后续还款方式，可选：每月还款额不变(reduce-terms)、贷款期限不变即每月还款额减少(reduce-payment) }]
        const loanTermOptions = ref([
                { "year": 1, "periods": 12, "note": "1年 (12期)" },
                { "year": 2, "periods": 24, "note": "2年 (24期)" },
                { "year": 3, "periods": 36, "note": "3年 (36期)" },
                { "year": 4, "periods": 48, "note": "4年 (48期)" },
                { "year": 5, "periods": 60, "note": "5年 (60期)" },
                { "year": 6, "periods": 72, "note": "6年 (72期)" },
                { "year": 7, "periods": 84, "note": "7年 (84期)" },
                { "year": 8, "periods": 96, "note": "8年 (96期)" },
                { "year": 9, "periods": 108, "note": "9年 (108期)" },
                { "year": 10, "periods": 120, "note": "10年 (120期)" },
                { "year": 11, "periods": 132, "note": "11年 (132期)" },
                { "year": 12, "periods": 144, "note": "12年 (144期)" },
                { "year": 13, "periods": 156, "note": "13年 (156期)" },
                { "year": 14, "periods": 168, "note": "14年 (168期)" },
                { "year": 15, "periods": 180, "note": "15年 (180期)" },
                { "year": 16, "periods": 192, "note": "16年 (192期)" },
                { "year": 17, "periods": 204, "note": "17年 (204期)" },
                { "year": 18, "periods": 216, "note": "18年 (216期)" },
                { "year": 19, "periods": 228, "note": "19年 (228期)" },
                { "year": 20, "periods": 240, "note": "20年 (240期)" },
                { "year": 25, "periods": 300, "note": "25年 (300期)" },
                { "year": 30, "periods": 360, "note": "30年 (360期)" }
            ]);
        const startDate = ref(new Date().toISOString().slice(0, 10));
        const periods = ref(Array.from({ length: loanTerm.value * 12 }, (_, i) => i + 1));

        const updatePeriodsOptions = (newMaxPeriods) => {
            periods.value = Array.from({ length: newMaxPeriods }, (_, i) => i + 1);
        };

        const resetLoan = () => {
            // clean up prepayments
            prepayments.value = [];
        };

        const calculateLoan = (loanAmount, rate, initialTerm, prepaymentsSchedule, loanType) => {
            const monthlyInterestRate = rate / 100 / 12;
            let numberOfPayments = initialTerm * 12;
            let outstandingBalance = loanAmount;
            let paymentList = [];
            let totalInterest = 0;
            let prepayments = prepaymentsSchedule;

            if (loanType === 'equalPrincipal') {  // 等额本金计算逻辑（包括提前还款逻辑）
                let monthlyPrincipal = loanAmount / numberOfPayments;
                let interestForThisPayment = 0
                for (let i = 0; i < numberOfPayments; i++) {
                    // 检查剩余还款是否小于月还款额
                    if (outstandingBalance <= monthlyPrincipal) {
                        monthlyPrincipal = outstandingBalance;
                        interestForThisPayment = outstandingBalance * monthlyInterestRate;
                        totalInterest += interestForThisPayment;
                        paymentList.push({
                            principal: monthlyPrincipal,
                            interest: interestForThisPayment,
                            totalPayment: monthlyPrincipal
                        });
                        break;
                    }

                    // 检查提前还款
                    const prepayment = prepayments.find(prepay => prepay.period === i + 1);
                    if (prepayment) {
                        console.log("prepayment:", prepayment, ", i:", i)
                        // 处理提前还款
                        outstandingBalance -= prepayment.amount;
                        if (prepayment.type === 'reduce-terms') {
                            // 减少还款期限，重新计算剩余的期数
                            numberOfPayments = i + Math.ceil(outstandingBalance / paymentList.at(-1).principal);
                            if (outstandingBalance < monthlyPrincipal) {
                                monthlyPrincipal = outstandingBalance;
                            }
                        } else {
                            // 减少每月还款额，重新计算每月还款额
                            monthlyPrincipal = outstandingBalance / (numberOfPayments - i);
                        }
                    }

                    console.log("monthlyPrincipal:", monthlyPrincipal, ", i:", i, "numberOfPayments:", numberOfPayments, "outstandingBalance:", outstandingBalance)

                    // 支付当期利息
                    interestForThisPayment = outstandingBalance * monthlyInterestRate;
                    totalInterest += interestForThisPayment;

                    paymentList.push({
                        principal: monthlyPrincipal,
                        interest: interestForThisPayment,
                        totalPayment: monthlyPrincipal + interestForThisPayment,
                    });

                    // 支付当期本金
                    if (outstandingBalance >= monthlyPrincipal) {
                        outstandingBalance -= monthlyPrincipal;
                    }
                }

                const totalPayment = loanAmount + totalInterest;

                return {
                    paymentList: paymentList,
                    firstMonthPayment: paymentList[0].totalPayment, // 初始月还款额
                    totalInterest: totalInterest,
                    totalPayment: totalPayment,
                    numberOfPayments: numberOfPayments
                };
            } else {    // 等额本息计算逻辑（包括提前还款逻辑）
                // 等额本息的初始月还款额计算
                const numeratorInitial = monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments);
                const denominatorInitial = Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1;
                let monthlyPayment = loanAmount * numeratorInitial / denominatorInitial;
                let newLoanTerm = 0;
                for (let i = 0; i < numberOfPayments; i++) {
                    // 检查剩余还款是否小于月还款额
                    if (outstandingBalance <= monthlyPayment) {
                        monthlyPayment = outstandingBalance;
                        const interestForThisPayment = outstandingBalance * monthlyInterestRate;
                        totalInterest += interestForThisPayment;
                        paymentList.push({
                            principal: monthlyPayment,
                            interest: interestForThisPayment,
                            totalPayment: monthlyPayment
                        });
                        break;
                    }
                    // 检查提前还款
                    const prepayment = prepayments.find(prepay => prepay.period === i + 1);
                    if (prepayment) {
                        // 处理提前还款
                        outstandingBalance -= prepayment.amount;
                        if (prepayment.type === 'reduce-terms') {
                            // 减少还款期限，需要重新计算剩余的期数和每月还款额
                            newLoanTerm = calculateNewLoanTerm(outstandingBalance, monthlyInterestRate, monthlyPayment, numberOfPayments);
                            numberOfPayments = i + newLoanTerm;
                        } else if (prepayment.type === 'reduce-payment') {
                            // 减少每月还款额，需要重新计算每月还款额
                            const numerator = monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments - i);
                            const denominator = Math.pow(1 + monthlyInterestRate, numberOfPayments - i) - 1;
                            monthlyPayment = outstandingBalance * numerator / denominator;
                        }
                    }

                    // 计算本月还款详情
                    const interestForThisPayment = outstandingBalance * monthlyInterestRate;
                    const principalForThisPayment = monthlyPayment - interestForThisPayment;
                    totalInterest += interestForThisPayment;

                    paymentList.push({
                        principal: principalForThisPayment,
                        interest: interestForThisPayment,
                        totalPayment: monthlyPayment,
                    });
                    if (outstandingBalance >= principalForThisPayment) {
                        outstandingBalance -= principalForThisPayment;
                    } else {
                        outstandingBalance = 0;
                    }
                }
                return {
                    paymentList: paymentList,
                    firstMonthPayment: paymentList[0].totalPayment,
                    totalInterest: paymentList.reduce((acc, payment) => acc + parseFloat(payment.interest), 0),
                    totalPayment: loanAmount + totalInterest,
                    numberOfPayments: numberOfPayments
                };
            }
        }
    
        const calculateMortgage = () => {
            let numberOfPayments = loanTerm.value * 12;

            // 排序提前还款计划，确保按期数升序排列
            let prepaymentsSchedulePublic = prepayments.value.filter(prepayment => prepayment.for === 'publicLoan');
            let prepaymentsScheduleCommercial = prepayments.value.filter(prepayment => prepayment.for === 'commercialLoan');
            prepaymentsSchedulePublic.sort((a, b) => a.period - b.period);
            prepaymentsScheduleCommercial.sort((a, b) => a.period - b.period);
            if (loanCategory.value === 'publicLoan') {
                // 公积金贷款
                const publicLoan = calculateLoan(loanAmountPublic.value, publicLoanInterestRate.value, loanTerm.value, prepaymentsSchedulePublic, loanType.value);
                numberOfPayments = publicLoan.numberOfPayments;
                emit('calculate', { ...publicLoan, loanCategory: loanCategory.value, startDate: startDate.value });
                return;
            } else if (loanCategory.value === 'commercialLoan') {
                // 商业贷款
                const commercialLoan = calculateLoan(loanAmount.value, interestRate.value, loanTerm.value, prepaymentsScheduleCommercial, loanType.value);
                numberOfPayments = commercialLoan.numberOfPayments;
                emit('calculate', { ...commercialLoan, loanCategory: loanCategory.value, startDate: startDate.value });
            } else if (loanCategory.value === 'combinedLoan') {
                // 组合贷款
                const commercialLoan = calculateLoan(loanAmount.value, interestRate.value, loanTerm.value, prepaymentsScheduleCommercial, loanType.value);
                const publicLoan = calculateLoan(loanAmountPublic.value, publicLoanInterestRate.value, loanTerm.value, prepaymentsSchedulePublic, loanType.value);
                numberOfPayments = commercialLoan.numberOfPayments;
                emit('calculate', {
                    paymentList: commercialLoan.paymentList,
                    firstMonthPayment: commercialLoan.firstMonthPayment,
                    totalInterest: commercialLoan.totalInterest,
                    totalPayment: commercialLoan.totalPayment,
                    paymentListPublicLoan: publicLoan.paymentList,
                    firstMonthPaymentPublicLoan: publicLoan.firstMonthPayment,
                    totalInterestPublic: publicLoan.totalInterest,
                    totalPaymentPublic: publicLoan.totalPayment,
                    loanCategory: loanCategory.value,
                    startDate: startDate.value
                });
            }
            updatePeriodsOptions(numberOfPayments);
        };

        const addPrepayment = () => {
            let newPeriod, newPrepareTime;
            // filter prepayments by loanCategory
            let category = loanCategory.value === 'publicLoan' || loanCategory.value === 'commercialLoan' ? loanCategory.value : 'commercialLoan';
            const currentPrepayments = prepayments.value.filter(prepayment => prepayment.for === category);
            console.log("addPrepayment currentPrepayments:", currentPrepayments);
            if (currentPrepayments.length > 0) {
                // 获取最后一次提前还款的信息
                const lastPrepayment = currentPrepayments[currentPrepayments.length - 1];
                newPeriod = lastPrepayment.period + 1; // 下一期
                newPrepareTime = getNextPrepaymentDate(lastPrepayment.prepareTime); // 下一期的具体时间
            } else {
                // 如果还没有提前还款，那么从第二期开始
                newPeriod = 2;
                newPrepareTime = getNextPrepaymentDate(startDate.value); // 贷款开始时间的下一期
            }

            // 确保新期数不超过最大期数
            const maxPeriod = periods.value.length;
            if (newPeriod > maxPeriod) {
                newPeriod = maxPeriod;
            }

            // 添加新的提前还款对象
            // Construct periods from newPeriod to length(periods)
            let currentPeriodOptions = Array.from({ length: maxPeriod - newPeriod + 1 }, (_, i) => i + newPeriod);
            prepayments.value.push({
                method: 'period', // 默认按期数
                period: newPeriod,
                prepareTime: newPrepareTime,
                periods: currentPeriodOptions,
                amount: 100000,
                for: category,
                type: 'reduce-payment', // 默认减少每月还款额
            });
        };

        const removePrepayment = (index) => {
            // Remove all periods after the index
            prepayments.value.splice(index);
        };

        // 计算下一期提前还款的日期
        const getNextPrepaymentDate = (currentPrepaymentDate) => {
            const date = new Date(currentPrepaymentDate);
            date.setMonth(date.getMonth() + 1); // 设置为下一期
            return date.toISOString().slice(0, 10);
        };

        // Calculate monthly payment based on loan term
        function calculateMonthlyPayment(principal, rate, term) {
            return (principal * rate * Math.pow(1 + rate, term)) / (Math.pow(1 + rate, term) - 1);
        }

            // Function to calculate the new loan term
        function calculateNewLoanTerm(
                newRemainingPrincipal,
                monthlyInterestRate,
                originalMonthlyPayment,
                originalLoanTerm
            ) {
                const tolerance = 1000;
                let minTerm = 1;
                let maxTerm = originalLoanTerm;
                let newLoanTerm = 0;
                let currentPayment;

                while (minTerm <= maxTerm) {
                    newLoanTerm = Math.floor((minTerm + maxTerm) / 2);
                    currentPayment = calculateMonthlyPayment(
                        newRemainingPrincipal,
                        monthlyInterestRate,
                        newLoanTerm
                    );

                    if (Math.abs(currentPayment - originalMonthlyPayment) <= tolerance) {
                        // Found a suitable term
                        return newLoanTerm;
                    } else if (currentPayment < originalMonthlyPayment) {
                        maxTerm = newLoanTerm - 1;
                    } else {
                        minTerm = newLoanTerm + 1;
                    }
                }

                throw new Error('Unable to find a new loan term that satisfies the conditions.');
            }

        const updatePeriodByDate = (prepayment, index) => {
            // 计算提前还款日期和开始日期之间的月份差
            const start = new Date(startDate.value);
            const prepare = new Date(prepayment.prepareTime);
            const months = (prepare.getFullYear() - start.getFullYear()) * 12 + prepare.getMonth() - start.getMonth();
            // 更新对应提前还款的期数
            prepayments.value[index].period = months >= 0 ? months : 0;
        };

        const updateDateByPeriod = (prepayment, index, period) => {
            // 根据期数计算对应的年份和月份
            const start = new Date(startDate.value);
            const newDate = new Date(start.setMonth(start.getMonth() + parseInt(period) - 1));
            // 更新对应提前还款的日期
            prepayments.value[index].prepareTime = newDate.toISOString().slice(0, 10);
        };

        const updatePrepayment = (prepayment, index, forLoan) => {
            // 更新对应提前还款的贷款类别
            if (index < prepayments.value.length - 1) {
                prepayments.value.splice(index + 1, prepayments.value.length - index - 1);
            }
            let targetLoanForPrepayments = prepayments.value.map((prepayment, index) => ({ prepayment, index })).filter(prepayment => prepayment.prepayment.for === forLoan);
            if (targetLoanForPrepayments.length > 1) {
                // 获取最后一次提前还款的信息
                const lastPrepayment = targetLoanForPrepayments[targetLoanForPrepayments.length - 2].prepayment;
                let currentPeriodOptions = Array.from({ length: periods.value.length - lastPrepayment.period + 1 }, (_, i) => i + lastPrepayment.period);
                prepayments.value[index].period = lastPrepayment.period + 1; // 下一期
                prepayments.value[index].prepareTime = getNextPrepaymentDate(lastPrepayment.prepareTime); // 下一期的具体时间
                prepayments.value[index].periods = currentPeriodOptions;
            } else {
                // 如果还没有提前还款，那么从第二期开始
                prepayments.value[index].period = 2;
                prepayments.value[index].prepareTime = getNextPrepaymentDate(startDate.value); // 贷款开始时间的下一期
                prepayments.value[index].periods = Array.from({ length: periods.value.length - 1 }, (_, i) => i + 2);
            }
        };

        // 根据期数计算对应的年份和月份
        const getPeriodYearMonth = (period) => {
            const start = new Date(startDate.value);
            const newDate = new Date(start.setMonth(start.getMonth() + period - 1));
            return `（${newDate.getFullYear()} 年 ${newDate.getMonth() + 1} 月）`;
        };

        return {
            loanCategory,
            loanType,
            loanAmount,
            interestRate,
            loanAmountPublic,
            publicLoanInterestRate,
            loanTerm,
            prepayments,
            loanTermOptions,
            startDate,
            periods,
            calculateMortgage,
            addPrepayment,
            removePrepayment,
            updatePeriodByDate,
            getPeriodYearMonth,
            updatePeriodsOptions,
            updateDateByPeriod,
            updatePrepayment,
            resetLoan
        };
    }
});
</script>
  
<style>
.calculator-form {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.input-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: center;
}

.radio-options {
    justify-content: center;
}

.input-group label {
    flex-basis: 40%;
    /* 给标签一定的空间 */
    margin-right: 10px;
    /* 在标签和输入框之间添加一些间距 */
}

.input-group input {
    flex-grow: 1;
    /* 输入框和选择框填充剩余空间 */
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.input-group select {
    flex-grow: 1;
    /* 输入框和选择框填充剩余空间 */
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    background-color: white;
    -webkit-appearance: none;
    /* 移除默认的下拉箭头 */
    -moz-appearance: none;
    appearance: none;
    /* 为 select 添加向下箭头的背景图 */
    background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
    background-size: 12px;
}

.prepayment-inputs {
    border: 2px dashed #ccc;
    padding: 10px;
    margin-top: 10px;
}

.prepayment-options-group {
    margin-top: 20px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    position: relative;
    padding-top: 40px;
    padding-bottom: 0;
}

.remove-prepayment-btn {
  position: absolute; /* 确保您有这个声明 */
  top: 10px; /* 调整与父容器顶部的距离 */
  right: 10px; /* 调整与父容器右侧的距离 */
  padding: 0; /* 之前设置了 2px，如果按钮大小是20x20px, 可能不需要内边距 */
  height: 20px; /* 保持按钮大小不变 */
  width: 20px;
  font-size: 12px;
  color: #50596c;
  border: 1px solid #c2c9d6;
  background-color: #f8f9fa;
  border-radius: 4px;
  /* 移除这里的padding，因为已经设置了元素的宽高 */
}

.remove-prepayment-btn:hover {
  background-color: #e1e5eb;
  border-color: #aeb4be;
}

.remove-icon {
  display: block;
  font-size: 14px;
}

.dashed-btn {
    display: block;
    width: 100%;
    margin-top: 15px;
    /* 保持原有的顶部外边距 */
    margin-bottom: 20px;
    /* 新增底部外边距，根据需要调整 */
    padding: 10px 0;
    border: 2px dashed #ccc;
    background-color: #fff;
    border-radius: 5px;
    color: #bbb;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.dashed-btn:hover {
    background-color: #e7f5ff;
    /* 鼠标悬停时背景颜色改变 */
    border-color: #0056b3;
    /* 鼠标悬停时边框颜色加深 */
    color: #0056b3;
}


.calculate-btn {
    width: 100%;
    padding: 10px;
    border: none;
    background: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.calculate-btn:hover {
    background: #0056b3;
}
</style>