<template>
    <div class="payment-chart">
      <canvas ref="paymentChartCanvas"></canvas>
    </div>
  </template>
  
  <script>
  import { Chart, registerables } from 'chart.js';
  import { ref, watch, onMounted, onUnmounted } from 'vue';
  
  Chart.register(...registerables);
  
  export default {
    name: 'PaymentChart',
    props: {
      paymentData: {
        type: Object,
        required: true
      }
    },
    setup(props) {
      const paymentChartCanvas = ref(null);
      let chart = null;
  
      const createChart = (data) => {
        // In case paymentChartCanvas is not ready
        if (!paymentChartCanvas.value) {
          return;
        }
        if (!data) {
          return;
        }
        let paymentData = data.monthlyPayment;
        let loanCategory = data.loanCategory;

        const ctx = paymentChartCanvas.value.getContext('2d');
        const isMobile = window.innerWidth <= 600;
        if (isMobile) {
          ctx.canvas.height = 1200;
        }
        if (chart) {
          chart.destroy();
        }
        let datasets = [];
        if (loanCategory === 'publicLoan') {
          datasets = [
            {
              label: '公积金本金',
              data: paymentData.map(item => item.valuePrincipal),
              backgroundColor: 'rgba(255, 202, 40, 0.8)',
              stack: 'Stack 0'
            },
            {
              label: '公积金利息',
              data: paymentData.map(item => item.valueInterest),
              backgroundColor: 'rgba(33, 150, 243, 0.8)',
              stack: 'Stack 0'
            }
          ];
        } else if (loanCategory === 'commercialLoan') {
          datasets = [
            {
              label: '商业贷款本金',
              data: paymentData.map(item => item.valuePrincipal),
              backgroundColor: 'rgba(255, 202, 40, 0.8)',
              stack: 'Stack 0'
            },
            {
              label: '商业贷款利息',
              data: paymentData.map(item => item.valueInterest),
              backgroundColor: 'rgba(33, 150, 243, 0.8)',
              stack: 'Stack 0'
            }
          ];
        } else {
          datasets = [
            {
              label: '商业贷款本金',
              data: paymentData.map(item => item.valuePrincipal),
              backgroundColor: 'rgba(255, 202, 40, 0.8)',
              stack: 'Stack 0'
            },
            {
              label: '商业贷款利息',
              data: paymentData.map(item => item.valueInterest),
              backgroundColor: 'rgba(33, 150, 243, 0.8)',
              stack: 'Stack 0'
            },
            {
              label: '公积金本金',
              data: paymentData.map(item => item.valuePrincipalPublicLoan),
              backgroundColor: 'rgba(76, 175, 80, 0.8)', // Green from Material Design palette
              stack: 'Stack 0'
            },
            {
              label: '公积金利息',
              data: paymentData.map(item => item.valueInterestPublicLoan),
              backgroundColor: 'rgba(244, 67, 54, 0.8)', // Red from Material Design palette
              stack: 'Stack 0'
            }
          ];
        }
        const indexAxis = isMobile ? 'y' : 'x';
        chart = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: paymentData.map(item => item.label),
            datasets: datasets
          },
          options: {
            indexAxis: indexAxis,
            scales: {
              y: {
                beginAtZero: true,
                stacked: true,
              },
              x: {
                stacked: true,
                beginAtZero: true,
              }
            },
            plugins: {
              tooltip: {
                callbacks: {
                    afterFooter: function(context) {
                    // 计算总和
                    let sum = 0;
                    context.forEach(function(item) {
                        sum += isMobile ? item.parsed.x : item.parsed.y;
                      });
                      return `总计: ${sum.toFixed(2)}`;
                    },
                    label: function(context) {
                    // 获取当前数据点的值
                    let value = isMobile ? context.parsed.x : context.parsed.y;
                    return `${context.dataset.label}: ${value.toFixed(2)}`;
                    },
                },
                // 启用多数据集显示
                mode: isMobile ? 'y' : 'x',
                // 在同一索引的所有项目上显示 tooltip
                intersect: false,
              }
            }
          }
        });
      };
  
      watch(() => props.paymentData, (newVal) => {
        createChart(newVal);
      }, {
        deep: true,
        immediate: true
      });
  
      onMounted(() => {
        createChart(props.paymentData);
      });
  
      onUnmounted(() => {
        if (chart) {
          console.log('destroy chart');
          chart.destroy();
        }
      });
  
      return {
        paymentChartCanvas
      };
    }
  }
</script>
  
<style>
.payment-chart {
  margin-top: 20px;
}
  </style>
