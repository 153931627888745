<template>
  <div class="results-display" v-if="results">
    <!-- 当 loanCategory 为 combinedLoan 时的内容 -->
    <div v-if="results.loanCategory === 'combinedLoan'">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>商业贷款</th>
            <th>公积金贷款</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>首期（月）支付</td>
            <td>{{ results.firstMonthPayment.toFixed(2) }} 元</td>
            <td>{{ results.firstMonthPaymentPublicLoan.toFixed(2) }} 元</td>
          </tr>
          <tr>
            <td>总支付利息</td>
            <td>{{ results.totalInterest.toFixed(2) }} 元</td>
            <td>{{ results.totalInterestPublic.toFixed(2) }} 元</td>
          </tr>
          <tr>
            <td>总支付金额</td>
            <td>{{ results.totalPayment.toFixed(2) }} 元</td>
            <td>{{ results.totalPaymentPublic.toFixed(2) }} 元</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- 当 loanCategory 为 publicLoan 或 commercialLoan 时的内容 -->
    <div v-else-if="results.loanCategory === 'publicLoan' || results.loanCategory === 'commercialLoan'">
      <table>
        <tbody>
          <tr>
            <td>首期（月）支付</td>
            <td>{{ results.firstMonthPayment.toFixed(2) }} 元</td>
          </tr>
          <tr>
            <td>总支付利息</td>
            <td>{{ results.totalInterest.toFixed(2) }} 元</td>
          </tr>
          <tr>
            <td>总支付金额</td>
            <td>{{ results.totalPayment.toFixed(2) }} 元</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- 如果 loanCategory 不是以上任一种情况 -->
    <div v-else>
      <p>未知的贷款类型</p>
    </div>
  </div>
</template>


<script>
export default {
  name: 'ResultsDisplay',
  props: {
    results: Object
  }
}
</script>

<style>
.results-display {
  max-width: 600px; /* 与 CalculatorForm 宽度一致 */
  margin: auto; /* 上下边距保持一致，并且居中显示 */
  background: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 8px; /* 保持与 CalculatorForm 的 border-radius 一致 */
  padding: 20px;
  margin-top: 20px; /* 可以移除，因为已经通过 margin: auto 设置了 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 阴影样式与 CalculatorForm 一致 */
}

.results-display table {
  width: 100%;
  border-collapse: collapse;
}

.results-display th, .results-display td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.results-display th {
  background-color: #f8f9fa;
  font-weight: bold;
}

.results-display h4 {
  color: #007bff; /* 标题颜色 */
  margin-top: 5px;
  margin-bottom: 10px;
}

.results-display h2 {
  color: #007bff; /* 标题颜色 */
  margin-bottom: 15px;
}

.results-display ul {
  list-style-type: none;
  padding: 0;
  margin: 0; /* 移除默认外边距 */
}

.results-display li {
  background: white; /* 列表项背景 */
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px; /* 圆角边框 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 列表项阴影 */
  display: flex;
  justify-content: space-between; /* 分散对齐 */
  align-items: center;
  font-size: 16px;
}
</style>
