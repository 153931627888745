<template>
  <div id="app">
    <AppHeader />
    <CalculatorForm @calculate="handleCalculation" />
    <ResultsDisplay :results="calculationResults" />
    <PaymentChart :paymentData="paymentChartData" />
  </div>
  <!-- footer -->
  <footer class="footer">
    <p>©2023 <a href="fangdaizhushou.com">fangdaizhushou.com</a> 版权所有</p>
  </footer>

</template>

<script>
import { ref } from 'vue';
import AppHeader from './components/Header.vue';
import CalculatorForm from './components/CalculatorForm.vue';
import ResultsDisplay from './components/ResultsDisplay.vue';
import PaymentChart from './components/PaymentChart.vue';

export default {
  name: 'App',
  components: {
    AppHeader,
    CalculatorForm,
    ResultsDisplay,
    PaymentChart
  },
  setup() {
    // Declaring reactive properties using ref
    const calculationResults = ref(null);
    const paymentChartData = ref(null);

    // Method to handle calculation results
    const handleCalculation = (results) => {
      calculationResults.value = results;
      updateChartData(results);
    };

    const getTime = (startDate, paymentIndex) => {
      // return year-month-day string each year based on the startDate and paymentIndex
      const date = new Date(startDate);
      date.setMonth(date.getMonth() + paymentIndex);
      if ((paymentIndex + 1) % 12 === 1) {
        return `${date.getFullYear()}年`;
      } else {
        return `${paymentIndex + 1}期`;
      }
    };

    // Method to update chart data based on the calculation results
    const updateChartData = (results) => {
      let loanCategory = results.loanCategory;
      let startDate = results.startDate;
      if (loanCategory === 'publicLoan' || loanCategory === 'commercialLoan') {
        paymentChartData.value = {
          'monthlyPayment': results.paymentList.map((payment, index) => ({
            label: `${getTime(startDate, index)}`,
            valuePrincipal: parseFloat(payment.principal),
            valueInterest: parseFloat(payment.interest),
            valueTotal: parseFloat(payment.totalPayment),
          })),
          'loanCategory': loanCategory,
        };
      } else {
        const paymentList = results.paymentList || [];
        const paymentListPublicLoan = results.paymentListPublicLoan || [];
        const maxLength = Math.max(paymentList.length, paymentListPublicLoan.length);

        paymentChartData.value = {
          'monthlyPayment': Array.from({ length: maxLength }, (_, index) => {
            const payment = paymentList[index] || { principal: 0, interest: 0 };
            const paymentPublicLoan = paymentListPublicLoan[index] || { principal: 0, interest: 0 };

            return {
              label: `${getTime(startDate, index)}`,
              valuePrincipal: parseFloat(payment.principal),
              valueInterest: parseFloat(payment.interest),
              valuePrincipalPublicLoan: parseFloat(paymentPublicLoan.principal),
              valueInterestPublicLoan: parseFloat(paymentPublicLoan.interest),
              valueTotal: parseFloat(payment.totalPayment) + parseFloat(paymentPublicLoan.totalPayment),
            };
          }),
          'loanCategory': loanCategory
        };
      }
    };

    // Exposing the variables and functions to the template
    return {
      calculationResults,
      paymentChartData,
      handleCalculation
    };
  },
  mounted() {
    // setup the title
    document.title = '房贷计算器 - 简单易用的房贷计算工具';
    let meta = document.querySelector('meta[name="description"]');
    if (!meta) {
      meta = document.createElement('meta');
      meta.name = 'description';
      document.getElementsByTagName('head')[0].appendChild(meta);
    }
    meta.setAttribute('content', '房贷计算器是一个简单易用的房贷计算工具，可以计算等额本息、等额本金、组合贷款的还款计划、利息、总支付金额等。');
    // keywords
    let keywords = document.querySelector('meta[name="keywords"]');
    if (!keywords) {
      keywords = document.createElement('meta');
      keywords.name = 'keywords';
      document.getElementsByTagName('head')[0].appendChild(keywords);
    }
    keywords.setAttribute('content', '房贷计算器, 公积金贷款, 公积金贷款计算器, 房贷利率, 房贷计算, 按揭计算器, 房贷工具, 房贷利息计算, 如何计算房贷, 房贷计算方法, 房贷利率对比');

    // Google Analytics
    let script = document.createElement('script');
    script.setAttribute('async', '');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-EXGEDY0C4M';
    document.head.appendChild(script);

    script = document.createElement('script');
    script.textContent = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-EXGEDY0C4M');
    `;
    document.head.appendChild(script);
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
}

.footer {
  background-color: #007bff;
  color: white;
  padding: 10px 0;
  text-align: center;
  border-top: 1px solid #0056b3;
  border-radius: 0 0 4px 4px;
}

.footer a {
  color: #ddd;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer p {
  margin: 0;
  font-size: 0.9em;
}


</style>
