<template>
  <header class="app-header">
    <div class="header-content">
      <img class="logo" :src="logo" alt="房贷计算器">
      <h1>房贷计算器</h1>
    </div>
  </header>
</template>

<script>
import Logo from '@/assets/mortgage.svg'; // The path to your SVG file

export default {
  name: 'AppHeader',
  data() {
    return {
      logo: Logo
    };
  }
}
</script>

<style>
.app-header {
  background-color: #007bff;
  color: white;
  padding: 10px 0;
  text-align: center;
  border-radius: 4px 4px 0 0;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 100px; /* Set the height of your logo */
  width: auto; /* Maintain the aspect ratio of your logo */
  margin-right: 15px; /* Add some space between the logo and the title */
}

h1 {
  margin: 0; /* Remove default margin of h1 if necessary */
}
</style>
